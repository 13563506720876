<template>
  <div>
    <h1>UJET Recordings</h1>
      <div style="width: 50%">
        Search by Call Number or Phone Number:
        <input type="text" @input="query"/>
      </div>
      <VePagination
        :total="ujetRecordings.meta.total"
        :page-index="pageIndex"
        :page-size=25
        :layout="['total', 'prev', 'pager', 'next']"
        @on-page-number-change="onPageNumberChange"
      />
      <VeTable
        style="width:50%; "
        :fixed-header="true"
        max-height="50vh"
        :columns="columns"
        :table-data="ujetRecordings.payload"
        :border-around="true"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable, VePagination } from 'vue-easytable';


export default {
  components: {
    VeTable,
    VePagination
  },
  name: 'UjetRecordingsTable',
  methods: {
    fetchUjetRecordings(){
      this.$store.dispatch('ujetRecordings/get')
    },
    onPageNumberChange(pageIndex){
      this.pageIndex = pageIndex
      this.$store.dispatch('ujetRecordings/get', {query: this.queryString, page: this.pageIndex})
    },
    query(event){
      this.queryString = event.target.value
      this.$store.dispatch('ujetRecordings/get', {query: this.queryString, page: this.pageIndex})
    }
  },
  props: {
    pageIndex: {
      type: Number,
      default: 1,
    },
    queryString: {
      type: String,
      default: "",
    },
  },
  mounted(){
    this.fetchUjetRecordings()
  },
  computed: {
    ...mapGetters({
      ujetRecordings: "ujetRecordings/get"
    }),
    columns() {
      return [
        {
          field: 'filename',
          key: 'filename',
          title: 'filename',
          fixed: 'left',
          align: 'left',
          width: 50,
          renderBodyCell: ({ row }) => (<div>
            {row.filename}
          </div>)
        },
        {
          field: 'phoneNumber',
          key: 'phoneNumber',
          title: 'Phone Number',
          fixed: 'left',
          align: 'left',
          width: 50,
          renderBodyCell: ({ row }) => (<div>
            {row.phone_number}
          </div>)
        },
        {
          field: 'downloadUrl',
          key: 'downloadUrl',
          title: 'download url',
          fixed: 'left',
          align: 'left',
          width: 50,
          renderBodyCell: ({ row }) => (<div>
            <a href={row.download_url}>Download</a>
          </div>)
        }
      ]
    }
  }

}
</script>
